import React from 'react'
import styled from 'styled-components'
import EventFormatSelector, {
  StyledEventFormatSelector,
} from '../../components/EventFormatSelector'
import { breakpoints, layout } from '../../theme'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import DemandeLayout from '../../components/DemandeLayout'

const FormatPage = () => (
  <StyledFormatPage>
    <DemandeLayout>
      <SalesPictureTitle title="Sélectionnez le type d’événement que vous souhaitez organiser." />
      <EventFormatSelector />
    </DemandeLayout>
  </StyledFormatPage>
)

const StyledFormatPage = styled.div`
  background-color: #f5f5f5;

  .page-content {
    min-height: 100vh;
    margin: 0 auto;
    max-width: 700px;
    width: ${layout.width};
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  ${StyledEventFormatSelector} {
    margin: 20px auto 0;
    max-width: 600px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    ${StyledEventFormatSelector} {
      margin: 30px auto 0;
      max-width: 600px;
    }
  }
`

export default FormatPage
export { StyledFormatPage }
